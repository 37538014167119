import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getMetricIds } from "../api";
// import Instructions from "../components/Instructions";
import Navbar from "../components/Navbar";

function AllMetricsView() {
  const [metricIds, setMetricIds] = useState<string[]>([]);

  useEffect(() => {
    getMetricIds().then((r) => {
      setMetricIds(r.data);
    });
  }, []);

  return (
    <div className="AllMetrics">
      <Navbar />
      <h3>Metrics</h3>
      {/* <Instructions /> */}
      <ul>
        {metricIds.map((metricId) => (
          <li key={metricId}>
            <Link to={`/metric/${metricId}`}>{metricId}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AllMetricsView;
