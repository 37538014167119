import React, { useState, useEffect } from "react";
import { getMetric } from "../api";
import { Link, useParams } from "react-router-dom";
import "./MetricView.css";
import MetricNum from "../dashboard/components/MetricNum";

interface ParamTypes {
  metricId: string;
}

export default () => {
  const { metricId } = useParams<ParamTypes>();

  const [lastValue, setLastValue] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setLastValue(null);
    getMetric(metricId)
      .then((r) => {
        setLastValue(r.last);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setLastValue(null);
        console.error(err);
      });
  }, [metricId]);

  return (
    <div className="metric-number">
      <Link to={`/metric/${metricId}`}>{metricId}</Link>
      <div className="metric-view-container">
        <div className="metric-data">
          {loading ? (
            <div className="loading loading-lg"></div>
          ) : lastValue !== null ? (
            <MetricNum number={lastValue} />
          ) : (
            <strong>
              No data points for <code>{metricId}</code>
            </strong>
          )}
        </div>
      </div>
    </div>
  );
};
