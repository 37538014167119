import React from "react";

export interface Props {
  number: number | null | undefined;
}

export default ({ number }: Props) => (
  <div className="metric-latest-num">
    <div className="metric metric-num">{(number || "--").toLocaleString()}</div>
  </div>
);
