import React from "react";
import { Link, NavLink } from "react-router-dom";

export default () => (
  <header className="navbar">
      <section className="navbar-section">
        <Link to="/" className="navbar-brand text-bold mr-2">
          Dashboard
        </Link>
        <NavLink to="/" className="btn btn-link" exact>
          Home
        </NavLink>
        <a href="https://semgrep.dev/docs/language-support/" className="btn btn-link" target="_blank" rel="noopener">
          Languages
        </a>
        <NavLink to="/benchmarks" className="btn btn-link" exact>
          Benchmarks
        </NavLink>
        <NavLink to="/metrics" className="btn btn-link" exact>
          All Metrics
        </NavLink>
      </section>
    </header>
);
