import React, { useState, useEffect } from "react";
import { getMetric } from "../api";
import { useParams } from "react-router-dom";
import { Metric } from "../types";
import "./MetricView.css";
// import Instructions from "../components/Instructions";
import MetricGraphViz from "../components/MetricGraphViz";
import Navbar from "../components/Navbar";

interface ParamTypes {
  metricId: string;
}

export default () => {
  const { metricId } = useParams<ParamTypes>();
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [lastValue, setLastValue] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setMetrics([]);
    setLastValue(null);
    getMetric(metricId)
      .then((r) => {
        setMetrics(r.data);
        setLastValue(r.last);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setMetrics([]);
        setLastValue(null);
        console.error(err);
      });
  }, [metricId]);

  return (
    <div>
      <Navbar />
      <h1 className="metric-id">{metricId}</h1>
      <h3 className="metric-latest">
        Latest:{" "}
        <div className="metric metric-num">{(lastValue || "--").toLocaleString()}</div>
      </h3>
      <div className="metric-view-container">
        <div className="embed-link">
          <a
            href={`/metric/${metricId}/graph`}
            target="_blank"
            rel="noopener noreferrer"
          >
            embed
          </a>
        </div>
        {loading ? (
          <div className="loading loading-lg"></div>
        ) : metrics.length > 0 ? (
          <MetricGraphViz metrics={metrics} />
        ) : (
          <strong>
            No data points for <code>{metricId}</code> &mdash; See instructions below to add
          </strong>
        )}
      </div>

      {/* <Instructions metricId={metricId} /> */}
    </div>
  );
};
