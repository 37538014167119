import React from "react";
import Navbar from "../components/Navbar";
import DashboardItem from "../dashboard/DashboardItem";
import { DashboardItemDescription } from "../types";
import "./Home.css";

const HOME_PAGE_METRICS: DashboardItemDescription[] = [
  {
    metricId: "registry.rules.num",
    type: "number",
  },
  {
    metricId: "semgrep-rules.num",
  },
  // all GA languages as listed here: https://semgrep.dev/docs/language-support/
  {
    metricId: "semgrep.core.Csharp.parse.pct",
    type: "number",
  },
  {
    metricId: "semgrep.core.Go.parse.pct",
    type: "number",
  },
  {
    metricId: "semgrep.core.Java.parse.pct",
    type: "number",
  },
  {
    metricId: "semgrep.core.Js.parse.pct",
    type: "number",
  },
  {
    metricId: "semgrep.core.Python.parse.pct",
    type: "number",
  },
  {
    metricId: "semgrep.core.Ruby.parse.pct",
    type: "number",
  },
  {
    metricId: "semgrep.core.Scala.parse.pct",
    type: "number",
  },
  {
    metricId: "semgrep.core.Ts.parse.pct",
    type: "number",
  },
];

export default () => (
  <div className="Home">
    <Navbar />
    <div className="dashboard-graph-container">
      {HOME_PAGE_METRICS.map((item, idx) => (
        <DashboardItem item={item} key={idx} />
      ))}
    </div>
  </div>
);
