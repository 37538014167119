/*
  Render the semgrep benchmark results from the semgrep.bench.* metrics as
  a multi-group bar chart.
*/

import React, { useState, useEffect } from "react";
import { getMetric } from "../api";
import Navbar from "../components/Navbar";
import { BenchmarkGroup, BenchmarkVariant } from "../types";
import BenchmarksGraphViz from "../components/BenchmarksGraphViz";

const benchmarkGroupNames = [
  'big-js',
  'njsbox',
  'zulip'
]

const benchmarkVariantNames = [
  'std', // baseline, must appear in first position
  'max-cache',
  'no-bloom',
  'no-cache',
  'no-gc-tuning'
]

/*
  Since some benchmark groups take much longer than others, we normalize them
  by setting the baseline value to 1.
*/
function normalizeVariantValues(variants: BenchmarkVariant[]) : BenchmarkVariant[] {
  if (variants.length > 0) {
    let base = variants[0].value;
    return variants.map((x) => ({ ...x, value: x.value/base }));
  }
  else
    return [];
}

function getBenchmarkGroup(groupName: string): Promise<BenchmarkGroup> {
  let p = benchmarkVariantNames.map((variantName): Promise<BenchmarkVariant> => {
    let metricId = `semgrep.bench.${groupName}.${variantName}.duration`;
    return getMetric(metricId).then((r): BenchmarkVariant => {
      return {
        id: metricId,
        name: variantName,
        value: r.last || 0.0001 // because 0 breaks the whole chart
      }
    })
  });
  return Promise.all(p).then((variants: BenchmarkVariant[]): BenchmarkGroup => {
    return {
      name: groupName,
      variants: normalizeVariantValues(variants)
    }
  })
}

function getBenchmarkResults(): Promise<BenchmarkGroup[]> {
  let p = benchmarkGroupNames.map(getBenchmarkGroup);
  return Promise.all(p);
}

export default () => {
  const [benchmarkResults, setBenchmarkResults] = useState<BenchmarkGroup[]>([]);

  useEffect(() => {
    getBenchmarkResults().then(setBenchmarkResults);
  }, []);

  return (
    <div>
      <Navbar />
      <h3>Benchmarks</h3>
      <BenchmarksGraphViz benchmarkResults={benchmarkResults} />
    </div>
  );
};
