/*
  The bar chart with all the benchmarks on it
*/

import React from "react";
import { BenchmarkGroup } from "../types";
import {
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
} from "victory";

interface Props {
  benchmarkResults: BenchmarkGroup[];
}

interface DataPoint {
  x: string;
  y: number;
  label: string
}

function prepareData(benchRes: BenchmarkGroup[]): DataPoint[][] {
  if (benchRes.length === 0)
    return [];
  else
    return benchRes[0].variants.map((benchVariant, pos): DataPoint[] => {
      // let variantName = benchVariant.name;
      return benchRes.map((benchGroup): DataPoint => {
        let variant = benchGroup.variants[pos];
        let value = 100 * variant.value;
        return {
          x: benchGroup.name,
          y: value,
          label: `${value.toFixed(1)}% ${variant.name}`
        }
      });
  });
}

/*
  Spacing tips:
  - 'width' and 'height' are in arbitrary units as they only define the aspect
    ratio of the chart.
  - when adding a new benchmark group or variant, increase 'height'.
*/
export default ({benchmarkResults}: Props) => {
  let data = prepareData(benchmarkResults);
  return (
    <div>
      <VictoryChart horizontal
        width={1000}
        height={600}
        padding={70}
      >
        <VictoryLabel
          x={225}
          y={25}
          textAnchor="middle"
          text="Semgrep Test Duration Relative to Baseline"
        />
        <VictoryGroup
          offset={27} // = 1.5 * width
          colorScale={"qualitative"}
          style={{ data: { width: 18 }, labels: { fontSize: 8 } }}
        >
        {
          data.map((barGroup, pos) => (
            <VictoryBar
              key={pos}
              data={barGroup}
            />
          ))
        }
        </VictoryGroup>
      </VictoryChart>
    </div>
  );
};
