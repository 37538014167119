import React from "react";
import { Metric } from "../types";
import {
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";

export interface Props {
  metrics: Metric[];
}

export default ({ metrics }: Props) => {
  const metricsWithDates = metrics.map((m) => ({
    ...m,
    timestamp: new Date(m.timestamp),
  }));

  return (
    <div className="metric-chart-container">
      <VictoryChart
        theme={VictoryTheme.grayscale}
        width={1000}
        height={370}
        padding={{ top: 10, bottom: 80, left: 80, right: 10 }}
        domainPadding={10}
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) =>
              `${datum.value.toFixed(1)} @ ${datum.timestamp.toLocaleString()}`
            }
            labelComponent={<VictoryTooltip constrainToVisibleArea />}
          />
        }
        scale={{ x: "time" }}
      >
        <VictoryAxis />
        <VictoryAxis dependentAxis tickFormat={(t) => t.toFixed(1)} />
        <VictoryLine data={metricsWithDates} x="timestamp" y="value" />
      </VictoryChart>
    </div>
  );
};
