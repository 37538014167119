import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import AllMetricsView from "./screens/AllMetricsView";
import AllLanguagesView from "./screens/AllLanguagesView";
import MetricView from "./screens/MetricView";
import MetricGraphView from "./screens/MetricGraphView";
import Home from "./screens/Home";
import "./App.css";
import Navbar from "./components/Navbar";
import LangSummary from "./screens/LangSummary";
import MetricNumberView from "./screens/MetricNumberView";
import AllLanguagesEmbed from "./screens/AllLanguagesEmbed";
import BenchmarksView from "./screens/BenchmarksView";

const NotFound = () => (
  <div>
    <Navbar />
    Page Not Found
  </div>
);

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/metrics" exact component={AllMetricsView} />
          <Route path="/metric/:metricId/graph" component={MetricGraphView} />
          <Route path="/metric/:metricId/number" component={MetricNumberView} />
          <Route path="/metric/:metricId" component={MetricView} />
          <Route path="/languages" component={() => { window.open('https://semgrep.dev/docs/language-support/'); return null;} }/>
          <Route path="/languages/table" exact component={AllLanguagesEmbed} />
          <Route path="/language/:languageId" component={LangSummary} />
          <Route path="/benchmarks" component={BenchmarksView} />
          <Route path="/" exact component={Home} />
          <Route path="/" component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
