import React, { useState, useEffect } from "react";
import { getMetric } from "../api";
import { DashboardItemDescription, Metric } from "../types";
import MetricGraphViz from "../components/MetricGraphViz";
import { Link, useHistory } from "react-router-dom";
import MetricNum from "./components/MetricNum";
import "./DashboardItem.css";

export interface Props {
  item: DashboardItemDescription;
}

export default ({ item }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [lastValue, setLastValue] = useState<number | null>(null);
  const history = useHistory();
  const path = `/metric/${item.metricId}`;

  const reset = () => {
    setLoading(true);
    setMetrics([]);
    setLastValue(null);
  };

  useEffect(() => {
    reset();
    getMetric(item.metricId)
      .then((r) => {
        setMetrics(r.data);
        setLastValue(r.last);
        setLoading(false);
      })
      .catch((err) => {
        reset();
        console.error(err);
      });
  }, [item.metricId]);

  return (
    <div
      className="dashboard-item metric-flex-container"
      onClick={() => history.push(path)}
    >
      <Link className="dashboard-title" to={path}>
        {item.metricId}
        {lastValue !== null &&
          item.type !== "number" &&
          `: ${lastValue.toLocaleString()}`}
      </Link>
      <div className="metric-data">
        {loading ? (
          <div className="loading loading-lg" />
        ) : metrics.length > 0 ? (
          item.type === "number" ? (
            <MetricNum number={lastValue} />
          ) : (
            <MetricGraphViz metrics={metrics} />
          )
        ) : (
          <strong>no data points yet</strong>
        )}
      </div>
    </div>
  );
};
