export function checkStatus(response: Response): Response {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      throw new Error(response.statusText);
    }
  }
  
  export function fetchGet<T>(path: string): Promise<T> {
    const headers = {
      Accept: "application/json"
    };
    return fetch(path, { headers, credentials: "same-origin" })
      .then(checkStatus)
      .then(res => res.json());
  }
  
  export function fetchPost<B, T>(path: string, body: B): Promise<T> {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    return fetch(path, {
      method: "post",
      headers,
      credentials: "same-origin",
      body: JSON.stringify(body)
    })
      .then(checkStatus)
      .then(res => res.json());
  }
  
  export function fetchPut<B, T>(path: string, body: B): Promise<T> {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    return fetch(path, {
      method: "put",
      headers,
      credentials: "same-origin",
      body: JSON.stringify(body)
    })
      .then(checkStatus)
      .then(res => res.json());
  }
  
  export function fetchPatch<B, T>(path: string, body: B): Promise<T> {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    return fetch(path, {
      method: "PATCH",
      headers,
      credentials: "same-origin",
      body: JSON.stringify(body)
    })
      .then(checkStatus)
      .then(res => res.json());
  }
  
  export function fetchDelete<T>(path: string): Promise<T> {
    const headers = {
      credentials: "same-origin"
    };
    return fetch(path, { method: "DELETE", headers, credentials: "same-origin" })
      .then(checkStatus)
      .then(res => res.json());
  }