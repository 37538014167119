import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import DashboardItem from "../dashboard/DashboardItem";
import { DashboardItemDescription
 } from "../types";
import "./LangSummary.css";

interface ParamTypes {
  languageId: string;
}

export interface LanguageSummary {
  name?: string;
  summaryNotes?: string;
  parseItems?: DashboardItemDescription[];
  perfItems?: DashboardItemDescription[];
  testingItems?: DashboardItemDescription[];
}

export interface LanguageSummaries {
  [key: string]: LanguageSummary;
}

const LANGUAGE_SUMMARIES: LanguageSummaries = {
  go: {
    parseItems: [
      {
        metricId: "semgrep.core.go.parse-coverage-lines.num",
      },
      { metricId: "semgrep.core.go.parse.pct" },
    ],
    testingItems: [{ metricId: "semgrep.core.tests.go.unit-test.num" }, { metricId: "semgrep.core.go.coverage.matching.pct" }],
  },
  java: {
    parseItems: [
      {
        metricId: "semgrep.core.java.parse-coverage-lines.num",
      },
      { metricId: "semgrep.core.java.parse.pct" },
    ],
    testingItems: [{ metricId: "semgrep.core.tests.java.unit-test.num" }, { metricId: "semgrep.core.java.coverage.matching.pct" }],
  },
  javascript: {
    name: "JavaScript",
    summaryNotes: "JavaScript is one of Semgrep's best supported languages",
    parseItems: [
      {
        metricId: "semgrep.core.javascript.parse-coverage-lines.num",
      },
      { metricId: "semgrep.core.javascript.parse.pct" },
    ],
    testingItems: [{ metricId: "semgrep.core.tests.js.unit-test.num" }, { metricId: "semgrep.core.javascript.coverage.matching.pct" }],
  },
  typescript: {
    name: "TypeScript",
    parseItems: [
      {
        metricId: "semgrep.core.typescript.parse-coverage-lines.num",
      },
      { metricId: "semgrep.core.typescript.parse.pct" },
      {
        metricId: "semgrep.core.tsx.parse-coverage-lines.num",
      },
      { metricId: "semgrep.core.tsx.parse.pct" },
    ],
    testingItems: [{ metricId: "semgrep.core.typescript.coverage.matching.pct" }],
  },
  ruby: {
    parseItems: [
      {
        metricId: "semgrep.core.ruby.parse-coverage-lines.num",
      },
      { metricId: "semgrep.core.ruby.parse.pct" },
    ],
    testingItems: [{ metricId: "semgrep.core.tests.ruby.unit-test.num" }, { metricId: "semgrep.core.ruby.coverage.matching.pct" }],
  },
  c: {
    testingItems: [
      {
        metricId: "semgrep.core.tests.c.unit-test.num",
      }, { metricId: "semgrep.core.c.coverage.matching.pct" }
    ],
  },
  json: {
    name: "JSON",
    testingItems: [{ metricId: "semgrep.core.tests.json.unit-test.num" }, { metricId: "semgrep.core.json.coverage.matching.pct" }],
  },
  ocaml: {
    name: "OCaml",
    testingItems: [{ metricId: "semgrep.core.tests.ocaml.unit-test.num" }, { metricId: "semgrep.core.ocaml.coverage.matching.pct" }],
  },
  php: {
    name: "PHP",
    testingItems: [{ metricId: "semgrep.core.tests.php.unit-test.num" }, { metricId: "semgrep.core.php.coverage.matching.pct" }],
  },
  yaml: {
    name: "YAML",
    testingItems: [{ metricId: "semgrep.core.tests.yaml.unit-test.num" }, { metricId: "semgrep.core.yaml.coverage.matching.pct" }],
  },
  python: {
    testingItems: [{ metricId: "semgrep.core.tests.python.unit-test.num" }, { metricId: "semgrep.core.python.coverage.matching.pct" }],
  },
  generic: {
    name: "Generic",
  },
  rust: {
    parseItems: [
      {
        metricId: "semgrep.core.rust.parse-coverage-lines.num",
      },
      { metricId: "semgrep.core.rust.parse.pct" },
    ],
    testingItems: [{ metricId: "semgrep.core.tests.rust.unit-test.num" }, ],
  },
  lua: {
    parseItems: [
      {
        metricId: "semgrep.core.lua.parse-coverage-lines.num",
      },
      { metricId: "semgrep.core.lua.parse.pct" },
    ],
    testingItems: [{ metricId: "semgrep.core.tests.lua.unit-test.num" }, ],
  },
  "c-sharp": {
    name: "C#",
    parseItems: [
      {
        metricId: "semgrep.core.c-sharp.parse-coverage-lines.num",
      },
      { metricId: "semgrep.core.c-sharp.parse.pct" },
    ],
    testingItems: [{ metricId: "semgrep.core.tests.csharp.unit-test.num" }, ],
  },
  r: {
    parseItems: [
      {
        metricId: "semgrep.core.r.parse-coverage-lines.num",
      },
      { metricId: "semgrep.core.r.parse.pct" },
    ],
    testingItems: [{ metricId: "semgrep.core.tests.r.unit-test.num" }, ],
  },
  kotlin: {
    parseItems: [
      {
        metricId: "semgrep.core.kotlin.parse-coverage-lines.num",
      },
      { metricId: "semgrep.core.kotlin.parse.pct" },
    ],
    testingItems: [{ metricId: "semgrep.core.tests.kotlin.unit-test.num" }, ],
  },
};

const renderMetrics = (title: string, metrics?: DashboardItemDescription[]) => (
  <div className="metric-collection">
    <h3>{title}</h3>
    {metrics && metrics.length > 0 ? (
      <div className="dashboard-graph-container">
        {metrics.map((item, idx) => (
          <DashboardItem item={item} key={idx} />
        ))}
      </div>
    ) : (
      <div className="no-data">automated metrics coming soon</div>
    )}
  </div>
);

export default () => {
  const { languageId } = useParams<ParamTypes>();

  const langSummary: LanguageSummary | undefined =
    LANGUAGE_SUMMARIES[languageId];

  return (
    <div className="LangSummary">
      <Navbar />
      {langSummary === undefined ? (
        <div>{languageId} does not have automated metrics yet</div>
      ) : (
        <div>
          <h2 className="lang-title">{langSummary.name || languageId}</h2>
          {langSummary.summaryNotes && <p>{langSummary.summaryNotes}</p>}
          {renderMetrics("Parsing Metrics", langSummary.parseItems)}
          {renderMetrics("Performance Metrics", langSummary.perfItems)}
          {renderMetrics("Testing Metrics", langSummary.testingItems)}
        </div>
      )}
    </div>
  );
};
