import React, { useState, useEffect } from "react";
import { getMetric } from "../api";
import { Link, useParams } from "react-router-dom";
import { Metric } from "../types";
import "./MetricView.css";
import MetricGraphViz from "../components/MetricGraphViz";

interface ParamTypes {
  metricId: string;
}

export default () => {
  const { metricId } = useParams<ParamTypes>();

  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [lastValue, setLastValue] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setMetrics([]);
    setLastValue(null);
    getMetric(metricId)
      .then((r) => {
        setMetrics(r.data);
        setLastValue(r.last);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setLastValue(null);
        setMetrics([]);
        console.error(err);
      });
  }, [metricId]);

  return (
    <div className="metric-graph">
      <Link to={`/metric/${metricId}`}>
        {metricId}
        {lastValue !== null && `: ${lastValue.toLocaleString()}`}
      </Link>
      <div className="metric-view-container">
        <div className="metric-data">
          {loading ? (
            <div className="loading loading-lg"></div>
          ) : metrics.length > 0 ? (
            <MetricGraphViz metrics={metrics} />
          ) : (
            <strong>
              No data points for <code>{metricId}</code>
            </strong>
          )}
        </div>
      </div>
    </div>
  );
};
