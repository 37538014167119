import { fetchGet } from "./fetch";
import { AllLanguagesResponse, MetricIdsResponse, MetricResponse } from "./types";

export const getMetricIds = (): Promise<MetricIdsResponse> => {
    return fetchGet<MetricIdsResponse>(`/api/metric`);
};

export const getMetric = (metricId: string): Promise<MetricResponse> => {
    return fetchGet<MetricResponse>(`/api/metric/${metricId}`);
};

export const getAllLangs = (): Promise<AllLanguagesResponse> => {
    return fetchGet<AllLanguagesResponse>(`/api/languages`);
}
