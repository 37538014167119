import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Language, LanguageStatus } from "../types";

export interface Props {
  languages: Language[];
  embedded?: boolean;
}

const langStatusToLabelClass = (langStatus?: LanguageStatus): string => {
  switch (langStatus) {
    case 'develop':
      return "";
    case "alpha":
      return " label-error";
    case "beta":
      return " label-warning";
    case "GA":
      return " label-success";
    case undefined:
      return " label-success";
    default:
      return "";
  }
};

const langStatusTohelpText = (langStatus?: LanguageStatus): string => {
  switch (langStatus) {
    case 'develop':
      return "experimental language under active \ndevelopment with many known bugs.\nLooking for dedicated users to help us \nimprove these languages";
    case "alpha":
      return "experimental language with many known bugs.\nLooking for dedicated users to help us \nimprove these languages";
    case "beta":
      return "supported language with known bugs.\nLooking for beta users to report bugs and \nrapidly iterate with our dev team";
    case "GA":
    case undefined:
    default:
      return "supported language with few known bugs.\nLooking for bug reports and feedback \nfrom users";
  }
};

export default ({ languages, embedded }: Props) => (
  <table className="table table-striped lang-table">
        <thead>
          <tr>
            <th>Language</th>
            <th>Status</th>
            <th>Extensions</th>
            <th>Tags</th>
          </tr>
        </thead>
        <tbody>
          {languages.map((lang) => (
            <tr key={lang.id}>
              <td>
                <Link target={embedded ? "_blank" : ""} to={`/language/${lang.linkId || lang.id}`} rel="noopener noreferrer">
                  {lang.name}
                </Link>
              </td>
              <td>
                <span className={`label${langStatusToLabelClass(lang.status)}`}>
                  {lang.status || "GA"}
                  <span
                    className="help-icon tooltip"
                    data-tooltip={langStatusTohelpText(lang.status)}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </span>
                </span>
              </td>
              <td>
                <span>
                  {lang.extensions.map((extension, i) => [
                    i > 0 && ", ",
                    <code>{extension}</code>
                  ])}
                </span>
              </td>
              <td>
                <span>
                  {lang.tags.map((tag, i) => [
                    i > 0 && ", ",
                    <code>{tag}</code>
                  ])}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
);
