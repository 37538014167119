import React, { useState, useEffect } from "react";
import { Language } from "../types";
import { getAllLangs } from "../api";
import LanguageTable from "../components/LanguageTable";


export default () => {
  const [languages, setLanguages] = useState<Language[]>([]);

  useEffect(() => {
    getAllLangs().then((r) => {
      setLanguages(r.data);
    });
  }, []);
  return (
    <div className="all-languages">
      <LanguageTable languages={languages} embedded />
    </div>
  );
};
